import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useTranslation } from 'react-i18next';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';

interface Props {
  processNumber: BookingJSON['processNumber'];
  isProcessNumberRequired: boolean;
  isCommitment: boolean;
  onChange(processNumber: BookingJSON['processNumber']): void;
}

export function PoNumber(props: Props) {
  const { t } = useTranslation('approvalRequests');

  if (props.isCommitment) {
    if (!props.processNumber) {
      return null;
    }

    return <ProcessNumber
      label={t('booking:processNumber')}
      processNumber={props.processNumber}
    />;
  }

  return <ProcessNumberInput
    defaultValue={props.processNumber}
    isDisabled={!!props.isCommitment}
    isRequired={props.isProcessNumberRequired}
    onChange={({ target }) => props.onChange(target.value)}
  />;
}