import { ApprovalProcessUpsertBody } from '@jurnee/common/src/dtos/approvalProcesses';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/approvalProcesses';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';
import { getUserStatsThunk } from '../userStats/userStats.thunks';

export interface ApprovalProcessCreatePayload {
  data: ApprovalProcessUpsertBody;
  onError(error: unknown): void;
  onSuccess(approvalProcess: ApprovalProcessJSON): void;
}

export interface ApprovalProcessUpdatePayload extends ApprovalProcessCreatePayload {
  id: ApprovalProcessJSON['id'];
}

export interface ApprovalProcessDeletePayload {
  id: ApprovalProcessJSON['id'];
  onError(error: unknown): void;
  onSuccess(approvalProcess: ApprovalProcessJSON): void;
}

export const getApprovalProcesses = createAsyncThunk<List<ApprovalProcessJSON, Record<never, string>>, null, { state: RootState }>('APPROVAL_PROCESSES_GET', async (_args, thunkAPI) => {
  try {
    const approvalProcesses = await api.getApprovalProcesses();
    return approvalProcesses;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your approval processes`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createApprovalProcess = createAsyncThunk<ApprovalProcessJSON, ApprovalProcessCreatePayload, { state: RootState }>('APPROVAL_PROCESSES_CREATE', async ({ data, onSuccess, onError }, thunkAPI) => {
  try {
    const approvalProcess = await api.createApprovalProcess(data);
    onSuccess(approvalProcess);

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalProcess;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateApprovalProcess = createAsyncThunk<ApprovalProcessJSON, ApprovalProcessUpdatePayload, { state: RootState }>('APPROVAL_PROCESSES_UPDATE', async ({ data, id, onSuccess, onError }, thunkAPI) => {
  try {
    const approvalProcess = await api.updateApprovalProcess(data, id);
    onSuccess(approvalProcess);

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalProcess;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const deleteApprovalProcess = createAsyncThunk<ApprovalProcessJSON, ApprovalProcessDeletePayload, { state: RootState }>('APPROVAL_PROCESSES_DELETE', async ({ id, onSuccess, onError }, thunkAPI) => {
  try {
    const approvalProcess = await api.deleteApprovalProcess(id);
    onSuccess(approvalProcess);

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalProcess;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});