import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { getTimesFromPeriodicity } from '@jurnee/common/src/utils/dates';
import { isAdmin } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { TrackEventOptions, trackEvent } from 'src/store/analytics/analytics.thunks';
import { getBookingsInvoices } from 'src/store/bookingsInvoices/bookingsInvoices.thunks';
import { getBudgets } from 'src/store/budgets/budgets.thunks';
import { getEntities } from 'src/store/entities/entities.thunks';
import { ProviderDiscountAddPayload, addProviderDiscount, getProviderDiscounts } from 'src/store/providerDiscounts/providerDiscounts.thunks';
import { RootState } from 'src/store/state';
import { teamsSelectors } from 'src/store/teams/teams.selectors';
import { getTeams } from 'src/store/teams/teams.thunks';
import { mapFrequencyToPeriodicity } from 'src/utils/date';
import { RouteProps } from '../../Route';
import BookingsInvoices from './BookingsInvoices';
import { Configuration } from './Configuration';
import Entities from './Entities';

interface StateProps {
  bookingsInvoices: RootState['bookingsInvoices'];
  budgets: RootState['budgets'];
  company: RootState['company'];
  entities: RootState['entities'];
  providerDiscounts: RootState['providerDiscounts'];
  teams: TeamJSON[];
  user: RootState['user'];
}

interface DispatchProps {
  addProviderDiscount(payload: ProviderDiscountAddPayload): void;
  getBookingsInvoices(filters: BookingsInvoicesSearchParams): void;
  getBudgets(): void;
  getEntities(): void;
  getProviderDiscounts(): void;
  getTeams(): void;
  trackEvent(opts: TrackEventOptions): void;
}

interface State {
  loading: boolean;
  budgetId: number | null;
  periodicity: string;
}

type Props = StateProps & DispatchProps & WithTranslation & RouteProps;

class BillingPage extends React.Component<Props> {

  state: State = this.getDefaultState();

  getDefaultState(): State {
    const queryParams = new URLSearchParams(this.props.location.search);
    const budgetId = queryParams.has('budgetId') ? Number(queryParams.get('budgetId')) : null;
    const periodicity = queryParams.has('frequency') ? mapFrequencyToPeriodicity(queryParams.get('frequency')) : 'current_year';

    return {
      loading: false,
      budgetId,
      periodicity
    };
  }

  get defaultSearchParams() {
    const params: BookingsInvoicesSearchParams = getTimesFromPeriodicity(this.state.periodicity);

    if (this.state.budgetId) {
      params.budgetIds = [this.state.budgetId];
    }

    return params;
  }

  componentDidMount() {
    if (!isAdmin(this.props.user)) {
      return;
    }

    this.props.getEntities();
    this.props.getBudgets();
    this.props.getTeams();
    this.props.getBookingsInvoices(this.defaultSearchParams);
  }

  render() {
    const { budgetId, periodicity } = this.state;

    if (!isAdmin(this.props.user)) {
      return <Navigate to='/' />;
    }

    return (
      <main>
        <Tabs w="100%">
          <Box bg="white" w="100%">
            <Box p={8}>
              <Heading>
                {this.props.t('header')}
              </Heading>
            </Box>

            <TabList>
              <Tab>{this.props.t('invoices.title')}</Tab>
              <Tab>{this.props.t('entities.title')}</Tab>
              <Tab>{this.props.t('configuration.title')}</Tab>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <BookingsInvoices budgetId={budgetId} periodicity={periodicity} />
            </TabPanel>
            <TabPanel>
              <Entities entities={this.props.entities} />
            </TabPanel>
            <TabPanel>
              <Configuration />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    bookingsInvoices: state.bookingsInvoices,
    budgets: state.budgets,
    company: state.company,
    entities: state.entities,
    providerDiscounts: state.providerDiscounts,
    teams: teamsSelectors.selectAll(state),
    user: state.user,
  };
}

const mapDispatchToProps: DispatchProps = {
  addProviderDiscount,
  getBookingsInvoices,
  getBudgets,
  getEntities,
  getProviderDiscounts,
  getTeams,
  trackEvent,
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('billing')(BillingPage));