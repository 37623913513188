import { Heading, HStack, Link, Spacer, VStack } from '@chakra-ui/react';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import EditBookingItemDrawer from 'src/drawers/EditBookingItemDrawer';
import ItemCommentDrawer from 'src/drawers/ItemCommentDrawer';
import ItemCard from '../ItemCard';

interface OwnProps {
  heading: string;
  bookingsItems: BookingItemJSON[];
  experiences: ExperienceJSON[];
  products: ProductJSON[];
  currency: Currency;
  editDisabled: boolean;
  onRemove?(bookingItemId: number): void;
}

type Props = OwnProps & WithTranslation;

class EventSchedule extends React.PureComponent<Props> {

  getActions(bookingItem: BookingItemJSON, experience: ExperienceJSON, product: ProductJSON) {
    return (
      <HStack w="100%" spacing={5}>
        { bookingItem.internalComment && <ItemCommentDrawer heading={bookingItem.description} comment={bookingItem.internalComment} /> }

        <Spacer />

        {
          !this.props.editDisabled && [
            <EditBookingItemDrawer key={0} bookingItem={bookingItem} experience={experience} product={product}>
              <Link fontSize={14} lineHeight="16px" color="blue.400">
                { this.props.t('common:buttons.edit') }
              </Link>
            </EditBookingItemDrawer>,
            <ConfirmModal
              key={1}
              title={this.props.t('modals.removeItem.title')}
              message={<Trans i18nKey='booking:modals.removeItem.message' values={{ name: bookingItem.description }} />}
              buttonLabel={this.props.t('common:buttons.remove')}
              onConfirm={() => this.props.onRemove(bookingItem.id)}
            >
              <Link fontSize={14} lineHeight="16px" color="pink.400">
                { this.props.t('common:buttons.remove') }
              </Link>
            </ConfirmModal>
          ]
        }
      </HStack>
    );
  }

  get bookingsItems() {
    const items = sortByDate(this.props.bookingsItems, 'from')
      .map((bookingItem, i) => {
        const product = this.props.products.find(({ id }) => id === bookingItem.productId);
        const experience = this.props.experiences.find(({ id }) => id === product?.experienceId);

        return (
          <ItemCard
            key={bookingItem.id}
            stackProps={i > 0 ? { pt: 5, borderTop: '1px solid var(--chakra-colors-gray-200)' } : null}
            item={bookingItem}
            experience={experience}
            currency={this.props.currency}
            isProcessing={bookingItem.status === 'PROCESSING'}
          >
            { this.getActions(bookingItem, experience, product) }
          </ItemCard>
        );
      });

    return (
      <VStack w="100%" spacing={5}>
        { items }
      </VStack>
    );
  }

  render() {
    return (
      <VStack w="100%" alignItems="flex-start" spacing={3}>
        <Heading size="md">{this.props.heading}</Heading>

        <VStack w="100%" p={5} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} spacing={5}>
          { this.bookingsItems }
        </VStack>
      </VStack>
    );
  }

}

export default withTranslation('booking')(EventSchedule);