import { Box, FormControl, FormLabel, UseToastOptions, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderSetupIntent } from '@jurnee/common/src/entities/ProviderEntity';
import { getBookingInvoiceTotal, getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { CardElement, CardElementProps, useElements, useStripe } from '@stripe/react-stripe-js';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { createSetupIntent } from 'src/utils/payment';
import BookingPriceDetailsCard from '../BookingPriceDetailsCard';
import { PrimaryButton } from '../buttons';

interface StateProps {
  company: RootState['company'];
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
}

export interface OwnProps {
  bookingInvoice: BookingInvoiceJSON;
  entity: EntityJSON;
  onSetupIntentCreated(setupIntentId: ProviderSetupIntent['id']): void;
  disabled: boolean;
}

const CARD_ELEMENT_OPTIONS: CardElementProps['options'] = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#000000',
      color: '#1A202C',
      fontFamily: 'Inter, Arial, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#1A202C'
      },
      '::placeholder': {
        color: '#A0AEC0'
      }
    },
    invalid: {
      iconColor: 'pink',
      color: 'pink'
    }
  }
};

function PaymentForm(props: StateProps & DispatchProps & OwnProps) {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = React.useState(false);

  if (!stripe || !elements) {
    return null;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { token } = await stripe.createToken(elements.getElement(CardElement));

      const setupIntentId = await createSetupIntent(props.company.data, props.entity, token.id);

      props.onSetupIntentCreated(setupIntentId);
    } catch (err) {
      props.showToast({ title: 'An error occurred during payment', status: 'error' });
      setLoading(false);
    }
  };

  function renderCardDetailsForm() {
    return (
      <FormControl isRequired>
        <FormLabel>{t('booking:steps.commitment.creditCard.form.cardDetails.label')}</FormLabel>
        <Box border="1px solid" borderColor="gray.200" borderRadius={4} px={3} py="7px">
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Box>
      </FormControl>
    );
  }

  function renderPriceDetails() {
    const { subtotal, totalTax, totalDiscount, total, currency } = getBookingInvoicesTotalDetails([props.bookingInvoice]);

    return <BookingPriceDetailsCard
      subtotal={subtotal}
      totalTax={totalTax}
      totalDiscount={totalDiscount}
      total={total}
      currency={currency}
      showCurrencyWarning={true}
    />;
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', position: 'relative' }}>
      <VStack spacing={5} w="100%">
        { renderCardDetailsForm() }

        { renderPriceDetails() }

        <PrimaryButton w="100%" size="sm" type="submit" isDisabled={props.disabled}>
          Pay {formatPrice(getBookingInvoiceTotal(props.bookingInvoice), props.bookingInvoice.currency)}
        </PrimaryButton>
      </VStack>

      {loading && <Loader overlay/>}
    </form>
  );
}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company
  };
}

const mapDispatchToProps: DispatchProps = {
  showToast
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm);