import { HStack, Text, VStack } from '@chakra-ui/react';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { ApprovalResponses } from '../../../ApprovalResponses';

interface Props {
  approvalProcess: ApprovalProcessJSON | null;
  approvalRequest: ApprovalRequestJSON | null;
  users: UserJSON[];
}

export function ApproversSection(props: Props) {
  const approvers = useMemo(() => {
    if (!props.approvalProcess) {
      return [];
    }

    return props.approvalProcess.approvalRules.map(({ approverId }) => props.users.find(({ id }) => id === approverId));
  }, [props.approvalProcess, props.users]);

  return (
    <VStack w="100%" spacing="10px">
      {
        props.approvalRequest ? (
          <ApprovalResponses
            approvalRequest={props.approvalRequest}
            users={props.users}
          />
        ) : (
          approvers.map((approver, i) => (
            <HStack key={i} w="100%" pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200" justifyContent="space-between">
              <Text fontSize={14} noOfLines={1} fontWeight={700}>{getUserLabel(approver)}</Text>
              <Text fontSize={14} noOfLines={1} color="gray.400">{approver.email}</Text>
            </HStack>
          ))
        )
      }
    </VStack>
  );
}