import { Box, Divider, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { isRegistered } from '@jurnee/common/src/utils/bookingParticipants';
import { isBudgetVisible } from '@jurnee/common/src/utils/budgets';
import { useSelector } from 'react-redux';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { BookingDetailsStepper } from 'src/pages/BookingDetails/BookingDetailsStepper';
import { getBookingParticipantsFetchStatusSelector, getBookingParticipantsSelector } from 'src/store/bookingParticipants/bookingParticipants.selectors';
import { getBudgetBreakdownById } from 'src/store/budgetBreakdowns/budgetBreakdowns.selectors';
import { getEmployeesByEmails } from 'src/store/employees/employees.selectors';
import { getUserSelector } from 'src/store/user/user.selectors';
import { getUserBudgetBreakdownsByIds, getUserBudgetBreakdownsFetchStatusSelector } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';
import { CommitmentType } from 'src/utils/booking';
import { BookingBudgetCard } from '../../../../../components/Budget/BookingBudgetCard';
import { SubmitSection } from './SubmitSection';

interface Props {
  booking: BookingJSON;
  budget: BudgetJSON | null;
  relationships: BookingRelationshipsJSON;
  currency: Currency;
  commitmentType: CommitmentType;
  onNext(): void;
}

export function BookingSummary(props: Props) {
  const user = useSelector(getUserSelector);
  const bookingParticipants = useSelector(getBookingParticipantsSelector);
  const participantEmails = bookingParticipants.filter(isRegistered).map(({ participant }) => participant.email);
  const participantUsers = useSelector(getEmployeesByEmails(participantEmails));
  const participantUsersWithBudget = participantUsers.filter(user => !!user.budgetId);
  const userBudgetBreakdowns = useSelector(getUserBudgetBreakdownsByIds(participantUsersWithBudget.map(user => user.id)));
  const budgetBreakdown = useSelector(getBudgetBreakdownById(props.booking.budgetId));

  const bookingParticipantsFetchStatus = useSelector(getBookingParticipantsFetchStatusSelector);
  const userBudgetBreakdownsFetchStatus = useSelector(getUserBudgetBreakdownsFetchStatusSelector);

  const isLoading = [
    bookingParticipantsFetchStatus,
    userBudgetBreakdownsFetchStatus
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  const priceDetails = getBookingInvoicesTotalDetails(props.relationships.bookingsInvoices);
  const bookingTotal = priceDetails.subtotal - priceDetails.totalDiscount;
  const hasBudgetCard = participantUsersWithBudget.length > 0 || (props.budget && isBudgetVisible(props.budget, user.id));

  return (
    <Box bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <BookingDetailsStepper step="PLANNING" commitmentType={props.commitmentType} />

      <VStack p={5} spacing={5}>
        {
          hasBudgetCard && (
            <>
              <BookingBudgetCard
                bookingTotal={bookingTotal}
                budget={props.booking.budget}
                budgetBreakdown={budgetBreakdown}
                users={participantUsersWithBudget}
                userBudgetBreakdowns={userBudgetBreakdowns}
                currency={props.currency}
                isLoading={isLoading}
              />
              <Divider />
            </>
          )
        }

        <BookingPriceDetailsCard
          subtotal={priceDetails.subtotal}
          totalTax={priceDetails.totalTax}
          totalDiscount={priceDetails.totalDiscount}
          total={priceDetails.total}
          currency={props.currency}
          showCurrencyWarning={false}
        />

        <SubmitSection booking={props.booking} onNext={props.onNext} />
      </VStack>
    </Box>
  );
}