import { Box, HStack, Input, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { isEmpty, isValidEmail } from '@jurnee/common/src/utils/strings';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  user: UserDetails;
  employees: UserDetails[];
  currentUserSelectLabel: string;
  selectLabel: string;
  formLabel: string;
  onChange(data: State): void;
}

export interface State {
  data: {
    email: string;
    employee: UserDetails;
    employeeFirstName: UserDetails['firstName'];
    employeeLastName: UserDetails['lastName'];
    firstName: string;
    lastName: string;
  },
  selectedRadio: 'SIGN_MYSELF' | 'SEND_TO_USER' | 'SEND_TO_EMAIL';
  isValidState: boolean;
}

export const defaultState: State = {
  data: {
    email: '',
    employee: null,
    employeeFirstName: null,
    employeeLastName: null,
    firstName: '',
    lastName: '',
  },
  selectedRadio: 'SIGN_MYSELF',
  isValidState: true
};

class QuoteRecipientSelector extends React.PureComponent<Props> {

  state: State = {
    ...defaultState,
    data: {
      ...defaultState.data,
      employee: this.props.user,
      employeeFirstName: this.props.user.firstName,
      employeeLastName: this.props.user.lastName
    },
  };

  get isSelectRadio() {
    return this.state.selectedRadio === 'SEND_TO_USER';
  }

  get isEmailRadio() {
    return this.state.selectedRadio === 'SEND_TO_EMAIL';
  }

  onChange = (newState: State) => {
    const isValidState = this.isValidState(newState);

    this.setState({ ...newState, isValidState });
    this.props.onChange({ ...newState, isValidState });
  };

  isValidState(state: State) {
    const { employee, employeeFirstName, employeeLastName, firstName, lastName, email } = state.data;

    if (state.selectedRadio === 'SEND_TO_USER' && !employee) {
      return false;
    }

    if (state.selectedRadio === 'SEND_TO_USER' && employee && (isEmpty(employeeFirstName) || isEmpty(employeeLastName))) {
      return false;
    }

    if (state.selectedRadio === 'SEND_TO_EMAIL' && !isValidEmail(email)) {
      return false;
    }

    if (state.selectedRadio === 'SEND_TO_EMAIL' && (isEmpty(firstName) || isEmpty(lastName))) {
      return false;
    }

    return true;
  }

  handleRadio = (selectedRadio: State['selectedRadio']) => {
    if (selectedRadio === 'SIGN_MYSELF') {
      return this.onChange({
        ...this.state,
        data: {
          ...this.state.data,
          employee: this.props.user,
          employeeFirstName: this.props.user.firstName,
          employeeLastName: this.props.user.lastName
        },
        selectedRadio
      });
    }

    this.onChange({ ...this.state, selectedRadio });
  };

  handleSelectedEmployee = ({ value }: { value: UserDetails }) => {
    this.onChange({
      ...this.state,
      data: {
        ...this.state.data,
        employee: value,
        employeeFirstName: value.firstName,
        employeeLastName: value.lastName,
      },
      selectedRadio: 'SEND_TO_USER'
    });
  };

  handleFirstName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.onChange({
      ...this.state,
      data: {
        ...this.state.data,
        [this.isSelectRadio ? 'employeeFirstName' : 'firstName']: target.value
      }
    });
  };

  handleLastName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.onChange({
      ...this.state,
      data: {
        ...this.state.data,
        [this.isSelectRadio ? 'employeeLastName' : 'lastName']: target.value
      }
    });
  };

  handleEmail = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.onChange({
      ...this.state,
      data: {
        ...this.state.data,
        email: target.value
      }
    });
  };

  get defaultFirstNameValue() {
    const { employee, employeeFirstName, firstName } = this.state.data;

    return this.isSelectRadio ? employeeFirstName || employee?.firstName : firstName;
  }

  get defaultLastNameValue() {
    const { employee, employeeLastName, lastName } = this.state.data;

    return this.isSelectRadio ? employeeLastName || employee?.lastName : lastName;
  }

  get isFirstNameInputDisabled() {
    const { employee } = this.state.data;

    return this.isSelectRadio && employee && !isEmpty(employee.firstName);
  }

  get isLastNameInputDisabled() {
    const { employee } = this.state.data;

    return this.isSelectRadio && employee && !isEmpty(employee.lastName);
  }

  get employeeNameInputs() {
    const { employee } = this.state.data;

    if (this.isSelectRadio && !employee) {
      return null;
    }

    if (this.isSelectRadio && (!isEmpty(employee.firstName) && !isEmpty(employee.lastName))) {
      return null;
    }

    return (
      <HStack w="100%" spacing={5}>
        <Input
          name="firstName"
          size="sm"
          placeholder={this.props.t('form.firstName.placeholder')}
          defaultValue={this.defaultFirstNameValue}
          isDisabled={this.isFirstNameInputDisabled}
          onChange={this.handleFirstName}
        />

        <Input
          name="lastName"
          size="sm"
          placeholder={this.props.t('form.lastName.placeholder')}
          defaultValue={this.defaultLastNameValue}
          isDisabled={this.isLastNameInputDisabled}
          onChange={this.handleLastName}
        />
      </HStack>
    );
  }

  get employeesSelectOptions() {
    return this.props.employees.map(employee => {
      return {
        value: employee,
        label: getUserLabel(employee)
      };
    });
  }

  get select() {
    if (!this.isSelectRadio) {
      return null;
    }

    return (
      <VStack w="100%" spacing={5}>
        <Box w="100%" className="react-select-small">
          <SearchSelect<UserDetails>
            options={this.employeesSelectOptions}
            closeMenuOnSelect={true}
            openMenuOnFocus={true}
            maxMenuHeight={200}
            placeholder={this.props.t('form.select.placeholder')}
            onChange={this.handleSelectedEmployee}
            defaultValue={this.employeesSelectOptions.find(({ value }) => value.id === this.state.data.employee?.id)}
          />
        </Box>

        {this.employeeNameInputs}
      </VStack>
    );
  }

  get form() {
    if (!this.isEmailRadio) {
      return null;
    }

    return (
      <>
        {this.employeeNameInputs}

        <Input
          name="email"
          size="sm"
          placeholder={this.props.t('form.email.placeholder')}
          defaultValue={this.state.data.email}
          onChange={this.handleEmail}
        />
      </>
    );
  }

  render() {
    return (
      <RadioGroup w="100%" onChange={this.handleRadio} value={this.state.selectedRadio ? this.state.selectedRadio : null}>
        <VStack alignItems="flex-start" spacing={3}>
          <Radio size="sm" colorScheme="blue" value="SIGN_MYSELF">
            <Text fontSize={14} lineHeight="14px">{this.props.currentUserSelectLabel}</Text>
          </Radio>

          <Radio size="sm" colorScheme="blue" value="SEND_TO_USER">
            <Text fontSize={14} lineHeight="14px">{this.props.selectLabel}</Text>
          </Radio>

          {this.select}

          <Radio size="sm" colorScheme="blue" value="SEND_TO_EMAIL">
            <Text fontSize={14} lineHeight="14px">{this.props.formLabel}</Text>
          </Radio>

          {this.form}
        </VStack>
      </RadioGroup>
    );
  }

}

export default withTranslation('employees')(QuoteRecipientSelector);