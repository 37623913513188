import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Link } from '@chakra-ui/react';
import { sanitize } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';

interface OwnProps {
  heading: string;
  comment: string;
}

interface State {
  isOpen: boolean;
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
}

type Props = OwnProps & DispatchProps & WithTranslation;

class ItemCommentDrawer extends React.PureComponent<Props, State> {

  state: State = {
    isOpen: false
  };

  onClose = () => {
    this.setState({ ...this.state, isOpen: false });
  };

  onOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    this.setState({ ...this.state, isOpen: true });

    this.props.trackEvent({
      name: 'opened_item_details_drawer'
    });
  };

  get body() {
    return <DrawerBody
      w="100%"
      p={5}
      fontSize={14}
      sx={{ 'a': { textDecoration: 'underline' } }}
      dangerouslySetInnerHTML={{ __html: sanitize(this.props.comment, ['div']) }}
    />;
  }

  get header() {
    return (
      <DrawerHeader>
        {this.props.heading}
      </DrawerHeader>
    );
  }

  render() {
    return (
      <>
        <Link fontSize={14} lineHeight="16px" textDecoration="underline" onClick={this.onOpen}>
          { this.props.t('viewComment') }
        </Link>

        <Drawer isOpen={this.state.isOpen} onClose={this.onClose}>
          <DrawerOverlay />
          <DrawerContent maxWidth="400px">
            <DrawerCloseButton />
            {this.header}
            {this.body}
          </DrawerContent>
        </Drawer>
      </>
    );
  }

}

const mapDispatchToProps: DispatchProps = {
  trackEvent
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(withTranslation('booking')(ItemCommentDrawer));