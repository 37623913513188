import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { ApprovalStatusCard } from 'src/components/ApprovalStatusCard';

interface Props {
  approvalRequest: ApprovalRequestJSON;
  users: UserJSON[];
}

function getDate(approvalResponse: ApprovalResponseJSON) {
  switch(approvalResponse.status) {
  case 'APPROVED':
    return approvalResponse.approvedAt;
  case 'CANCELED':
    return approvalResponse.canceledAt;
  case 'REJECTED':
    return approvalResponse.rejectedAt;
  default:
    return approvalResponse.updatedAt;
  }
}

export function ApprovalResponses({ approvalRequest, users }: Props) {
  return approvalRequest.approvalResponses.map((approvalResponse, i) => {
    const approver = users.find(({ id }) => approvalResponse.userId === id);
    const date = getDate(approvalResponse);

    return <ApprovalStatusCard
      key={i}
      user={approver}
      status={approvalResponse.status}
      comment={approvalResponse.comment}
      date={date}
    />;
  });
}