import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getUserPaymentProfilesThunk } from './userPaymentProfiles.thunks';

const slice = createSlice({
  name: 'userPaymentProfiles',
  initialState: initialState.userPaymentProfiles,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUserPaymentProfilesThunk.fulfilled, (_, { payload }) => {
      return { data: payload, error: null, status: 'FETCHED' };
    });

  }
});

export default slice.reducer;