import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderSetupIntent } from '@jurnee/common/src/entities/ProviderEntity';
import { useState } from 'react';
import PaymentForm from 'src/components/Payment/PaymentForm';
import StripeWrapper from 'src/components/Payment/StripeWrapper';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import { CreditCardCommitPayload } from 'src/store/bookingDetails/bookingDetails.thunks';

interface Props {
  bookingInvoice: BookingInvoiceJSON;
  entity: EntityJSON;
  processNumber: BookingJSON['processNumber'];
  onSetupIntentCreated(data: CreditCardCommitPayload['data']): void;
  disabled: boolean;
}

export function CreditCardCard(props: Props) {
  const [processNumber, setProcessNumber] = useState(props.processNumber);

  function onSetupIntentCreated(setupIntentId: ProviderSetupIntent['id']) {
    props.onSetupIntentCreated({
      setupIntentId,
      processNumber
    });
  };

  return (
    <>
      <ProcessNumberInput
        defaultValue={processNumber}
        onChange={({ target }) => setProcessNumber(target.value)}
      />
      <StripeWrapper>
        <PaymentForm
          entity={props.entity}
          onSetupIntentCreated={onSetupIntentCreated}
          bookingInvoice={props.bookingInvoice}
          disabled={props.disabled}
        />
      </StripeWrapper>
    </>
  );
}