import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Select, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { sortBy } from '@jurnee/common/src/utils/arrays';
import { getCurrencyChar } from '@jurnee/common/src/utils/prices';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useMemo, useState } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { getUserSelector } from 'src/store/user/user.selectors';
import { trackEvent } from '../store/analytics/analytics.thunks';
import { updateUser } from '../store/user/user.thunks';

function UserSettingsDrawer(props: WithTranslation) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('employees');
  const user = useSelector(getUserSelector);

  const [firstName, setFirstName] = useState(user.firstName ?? '');
  const [lastName, setLastName] = useState(user.lastName ?? '');
  const [email, setEmail] = useState(user.email);
  const [currencyId, setCurrencyId] = useState(user.currency.id);
  const [isOpen, setIsOpen] = useState(false);

  const currencyOptions = useMemo(
    () => sortBy(user.currency.targetExchangeRates, 'sourceId')
      .map(({ sourceId }) =>
        <option key={sourceId} value={sourceId}>
          {sourceId} - {getCurrencyChar(sourceId)}
        </option>
      ),
    [user.currency.targetExchangeRates]
  );

  function onClose() {
    setIsOpen(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_user_settings_drawer'
    }));
  }

  async function onSave() {
    try {
      await dispatch(updateUser({
        id: user.id,
        data: { firstName, lastName, email, currencyId }
      })).unwrap();

      onClose();
      toast(getSuccessToast(t('drawer.toasts.success')));
    } catch (error) {
      toast(getErrorToast(t('drawer.toasts.error'), error.message));
    }
  }

  return (
    <>
      <MenuItemWrapper
        icon='user'
        label={props.t('navbar:userCard.settings')}
        onClick={onOpen}
      />

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {props.t('common:settings')}
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody w="100%" p={5}>
            <VStack spacing={5}>
              <InputFormControl size="sm" name="firstName" value={firstName} onChange={setFirstName} />
              <InputFormControl size="sm" name="lastName" value={lastName} onChange={setLastName} />
              <InputFormControl size="sm" name="email" value={email} type="email" onChange={setEmail} />
              <FormControl isRequired>
                <FormLabel>{props.t('common:fields.preferredCurrency.label')}</FormLabel>
                <Select size="sm" bg="white" value={currencyId} name="currencyId" onChange={(e) => setCurrencyId(e.target.value)}>
                  { currencyOptions }
                </Select>
              </FormControl>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                {props.t('common:buttons.close')}
              </SecondaryButton>
              <PrimaryButton size="sm" colorScheme="teal" onClick={onSave}>
                {props.t('common:buttons.save')}
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default withTranslation(['common', 'navbar'])(UserSettingsDrawer);