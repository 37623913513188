import { Text, VStack } from '@chakra-ui/react';

interface Props {
  label: string;
  helperText: string;
}

export function Label({ label, helperText }: Props) {
  return (
    <VStack alignItems="flex-start" spacing="2px">
      <Text fontWeight={700} lineHeight="16px">{label}</Text>
      <Text color="gray.400">{helperText}</Text>
    </VStack>
  );
}