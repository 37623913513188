import { FormControl, Text, Textarea, VStack } from '@chakra-ui/react';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { PoNumber } from '../PoNumber';
import { ApproversSection } from './ApproversSection';
import { SubmitButton } from './SubmitButton';

interface Props {
  approvalProcess: ApprovalProcessJSON | null;
  approvalRequest: ApprovalRequestJSON | null;
  bookingsInvoices: BookingInvoiceJSON[];
  isSelfApprovalEnabled: boolean;
  processNumber: BookingJSON['processNumber'];
  users: UserJSON[];
  disabled: boolean;
  onSubmit(data: ApprovalRequestCreateBody): Promise<void>;
}

export function ApprovalRequestCard(props: Props) {
  const { t } = useTranslation('approvalRequests');

  const [processNumber, setProcessNumber] = useState(props.processNumber);
  const [comment, setComment] = useState(props.approvalRequest ? props.approvalRequest.comment : null);

  const isProcessNumberRequired = !props.approvalRequest && props.isSelfApprovalEnabled && props.approvalProcess.processNumberRequired;
  const isApproversSection = props.approvalRequest || !props.isSelfApprovalEnabled;
  const isCommentForm = !props.approvalRequest && !props.isSelfApprovalEnabled;
  const isSelfApprovalLabel = !props.approvalRequest && props.isSelfApprovalEnabled;

  const priceDetails = useMemo(() => getBookingInvoicesTotalDetails(props.bookingsInvoices), [props.bookingsInvoices]);

  return (
    <VStack w="100%" spacing={5}>
      <PoNumber
        processNumber={processNumber}
        isCommitment={!!props.approvalRequest}
        isProcessNumberRequired={isProcessNumberRequired}
        onChange={setProcessNumber}
      />

      <VStack w="100%" alignItems="flex-start" spacing="10px">
        <Text fontSize={14} lineHeight="16px" fontWeight={700}>
          { t(`form.${isSelfApprovalLabel ? 'selfApproval' : 'requireApproval'}.label`) }
        </Text>

        {
          isApproversSection &&
            <ApproversSection
              approvalProcess={props.approvalProcess}
              approvalRequest={props.approvalRequest}
              users={props.users}
            />
        }

        {
          isCommentForm &&
            <FormControl id='comment'>
              <Textarea
                name="comment"
                size="sm"
                minHeight="55px"
                fontSize="14px"
                resize="none"
                placeholder={props.approvalRequest ? null :t('form.comment.placeholder')}
                _placeholder={{ color: 'gray.400' }}
                onChange={({ target }) => setComment(target.value)}
                defaultValue={comment}
                isDisabled={!!props.approvalRequest}
                _disabled={{ color: 'gray.400', bgColor: 'gray.50', cursor: 'not-allowed' }}
              />
            </FormControl>
        }
      </VStack>

      <BookingPriceDetailsCard
        subtotal={priceDetails.subtotal}
        totalTax={priceDetails.totalTax}
        totalDiscount={priceDetails.totalDiscount}
        total={priceDetails.total}
        currency={priceDetails.currency}
        showCurrencyWarning={true}
      />

      <SubmitButton
        approvalRequest={props.approvalRequest}
        isSelfApprovalEnabled={props.isSelfApprovalEnabled}
        processNumber={processNumber}
        disabled={props.disabled}
        isProcessNumberRequired={isProcessNumberRequired}
        onSubmit={() => props.onSubmit({ processNumber, comment })}
      />
    </VStack>
  );
}