import { PaymentProfileUpsertBody } from '../dtos/paymentProfiles';
import { PaymentProfileCreate, PaymentProfileJSON } from '../entities/PaymentProfile';
import { UserPaymentProfileJSON } from '../entities/UserPaymentProfile';

function getDefaultProfile(type: PaymentProfileJSON['type']): PaymentProfileUpsertBody {
  switch(type) {
  case 'APPROVAL_PROCESS':
    return { type, status: 'ENABLED', approvalProcessId: null };
  case 'CREDIT_CARD':
    return { type, status: 'ENABLED', userIds: [] };
  default:
    return { type, status: 'DISABLED' };
  }
}

export function getPaymentProfileData(paymentProfiles: PaymentProfileJSON[], usersPaymentProfiles: UserPaymentProfileJSON[], type: PaymentProfileJSON['type']): PaymentProfileUpsertBody {
  const profile = paymentProfiles.find(profile => profile.type === type);

  if (profile) {
    const { id, status, approvalProcessId } = profile;

    switch(type) {
    case 'APPROVAL_PROCESS':
      return { type, status, approvalProcessId };
    case 'CREDIT_CARD': {
      const userIds = usersPaymentProfiles.filter(({ paymentProfileId }) => paymentProfileId === id).map(({ userId }) => userId);
      return { type, status, userIds };
    }
    default:
      return { type, status };
    }
  }

  return getDefaultProfile(type);
}

export function getDefaultPaymentProfilesData(): Pick<PaymentProfileCreate, 'type' | 'status'>[] {
  return ['APPROVAL_PROCESS', 'CREDIT_CARD', 'QUOTE'].map(type => {
    const profile = getDefaultProfile(type as PaymentProfileJSON['type']);
    return { type: profile.type, status: profile.status };
  });
}