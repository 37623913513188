import { HStack, Input, Text, VStack, chakra } from '@chakra-ui/react';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { formatShortWithTime } from '@jurnee/common/src/utils/dates';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  providerDiscount: ProviderDiscountJSON | null;
  onApply(entityId: EntityJSON['id'], discountCode: string): void;
}

export function Discount({ providerDiscount, onApply }: Props) {
  const { t } = useTranslation(['billing', 'common']);

  const [discountCode, setDiscountCode] = useState('');

  const endDate = providerDiscount?.coupon?.redeem_by ? new Date(providerDiscount?.coupon.redeem_by * 1000) : null;

  return (
    <VStack w="100%" spacing="10px" alignItems="flex-start">
      <Text fontSize={16} lineHeight="16px" fontWeight={700} >
        { t('configuration.discount.label') }
      </Text>

      {
        providerDiscount?.coupon ? (
          <Text color="gray.400" pl={3} py={1} borderLeft="4px solid" borderColor="black">
            <Trans
              i18nKey="billing:configuration.discount.description"
              values={{
                name: providerDiscount.coupon.name,
                percent_off: providerDiscount.coupon.percent_off
              }}
              components={{
                strong: <chakra.span color="black" fontWeight={700} />
              }}
            />

            { endDate && ` ${t('configuration.discount.validUntil', { endDate: formatShortWithTime(endDate) })}` }
          </Text>
        ) : (
          <>
            <Text lineHeight="14px" color="gray.400">{t('configuration.discount.helpText')}</Text>

            <HStack w="100%" spacing={5}>
              <Input
                size="sm"
                placeholder={t('configuration.discount.placeholder')}
                onChange={({ target }) => setDiscountCode(target.value)}
              />

              <PrimaryButton size="sm" colorScheme="black" onClick={() => onApply(providerDiscount.entityId, discountCode)}>
                { t('common:buttons.apply') }
              </PrimaryButton>
            </HStack>
          </>
        )
      }
    </VStack>
  );
}