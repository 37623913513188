import { FormControl, HStack, Switch, VStack } from '@chakra-ui/react';
import { PaymentProfileJSON } from '@jurnee/common/src/entities/PaymentProfile';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';

interface Props {
  type: PaymentProfileJSON['type'];
  checked: boolean;
  onChange(checked: boolean): void;
  children?: React.ReactNode;
}

export function ProfileSwitch(props: Props) {
  const { t } = useTranslation('settings', { keyPrefix: `paymentProfiles.forms.${props.type}` });

  return (
    <HStack w="100%" spacing={2} alignItems="flex-start">
      <Switch size="sm" defaultChecked={props.checked} onChange={({ target }) => props.onChange(target.checked)} />

      <VStack w="100%" alignItems="flex-start" spacing={4}>
        <FormControl>
          <Label label={t('label')} helperText={t('helperText')} />
        </FormControl>
        { props.checked && props.children }
      </VStack>
    </HStack>
  );
}