import { Box, Heading, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { isApprovalProcessReviewer } from '@jurnee/common/src/utils/approvalProcesses';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { isValidEntity } from '@jurnee/common/src/utils/entities';
import { hasInitialStatus } from '@jurnee/common/src/utils/quotes';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from 'src/api/bookings';
import EventSchedule from 'src/components/BookingDetails/EventSchedule';
import { PrimaryButton } from 'src/components/buttons';
import EntityCard from 'src/components/EntityCard';
import EditEntityDrawer from 'src/drawers/EditEntityDrawer';
import { useAppDispatch } from 'src/store';
import { createQuote, CreditCardCommitPayload, getBooking } from 'src/store/bookingDetails/bookingDetails.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { CommitmentType, getPendingCommitmentType, isBookingEditDisabled } from 'src/utils/booking';
import { BookingDetailsStepper } from '../../BookingDetailsStepper';
import { ApprovalRequestCard } from './ApprovalRequestCard';
import { CommitmentMethodLinks } from './CommitmentMethodLinks';
import { CreditCardCard } from './CreditCardCard';
import { QuoteCard } from './QuoteCard';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  bookingInvoice: BookingInvoiceJSON;
  entity: EntityJSON;
  commitmentTypes: CommitmentType[];
  onApprovalRequestSubmit(data: ApprovalRequestCreateBody): Promise<void>;
  onSetupIntentCreated(data: CreditCardCommitPayload['data']): void;
  onRemoveBookingItem(bookingItemId: BookingItemJSON['id']): void;
}

export function CommitmentStep(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('booking');

  const user = useSelector(getUserSelector);

  const [processNumber, setProcessNumber] = useState(props.booking.processNumber);
  const [commitmentType, setCommitmentType] = useState(getPendingCommitmentType(props.relationships, props.commitmentTypes));

  const approvalRequest = useMemo(() => sortByDate(props.relationships.approvalRequests, 'createdAt', 'desc')[0], [props.relationships]);
  const quote = useMemo(() => sortByDate(props.relationships.quotes, 'createdAt', 'desc').find(hasInitialStatus), [props.relationships]);
  const isEntityValid = useMemo(() => isValidEntity(props.entity), [props.entity]);
  const isPendingCommitment = !!approvalRequest || !!quote;

  async function onApprovalRequestSubmit(data: ApprovalRequestCreateBody) {
    setProcessNumber(data.processNumber);

    await props.onApprovalRequestSubmit(data);
  };

  async function openQuote(quote: QuoteJSON) {
    const { url } = await api.getQuoteLink(props.booking.id, quote.id);

    if (url) {
      window.open(url, '_blank');

      const callback = () => {
        window.removeEventListener('focus', callback);
        dispatch(getBooking(props.booking.id));
      };

      window.addEventListener('focus', callback);
    } else {
      throw new Error('URL not found');
    }
  };

  async function onQuoteSubmit(data: QuoteCreateBody) {
    setProcessNumber(data.processNumber);

    try {
      const quote = await dispatch(createQuote({ bookingId: props.booking.id, data })).unwrap();

      if (quote.recipient.id === user.id && quote.providerMemberId) {
        try {
          await openQuote(quote);
        } catch (error) {
          const title = t('steps.commitment.quote.toasts.error');
          toast(getErrorToast(title));
        }
      }

      toast(getSuccessToast(t('steps.commitment.quote.toasts.success')));
    } catch (err) {
      const title = err instanceof Error ? err.message : t('steps.commitment.quote.toasts.error');
      toast(getErrorToast(title));
    }
  };

  function formCard() {
    if (commitmentType === 'APPROVAL_REQUEST') {
      const [approvalProcess] = props.relationships.approvalProcesses;

      return <ApprovalRequestCard
        approvalRequest={approvalRequest}
        approvalProcess={approvalProcess}
        bookingsInvoices={props.relationships.bookingsInvoices}
        isSelfApprovalEnabled={approvalProcess && isApprovalProcessReviewer(approvalProcess, user.id)}
        processNumber={processNumber}
        users={props.relationships.users}
        onSubmit={onApprovalRequestSubmit}
        disabled={!isEntityValid}
      />;
    }

    if (commitmentType === 'QUOTE') {
      return <QuoteCard
        quote={quote}
        processNumber={processNumber}
        bookingsInvoices={props.relationships.bookingsInvoices}
        onSubmit={onQuoteSubmit}
        disabled={!isEntityValid}
      />;
    }

    return <CreditCardCard
      bookingInvoice={props.bookingInvoice}
      entity={props.entity}
      processNumber={processNumber}
      onSetupIntentCreated={props.onSetupIntentCreated}
      disabled={!isEntityValid}
    />;
  }

  return (
    <HStack width="100%" spacing={8} alignItems="flex-start">
      <Box w="100%">
        <EventSchedule
          heading={t('schedule.heading')}
          bookingsItems={props.booking.bookingsItems}
          experiences={props.relationships.experiences}
          products={props.relationships.products}
          currency={props.bookingInvoice.currency}
          editDisabled={isBookingEditDisabled(props.booking, props.relationships)}
          onRemove={props.onRemoveBookingItem}
        />
      </Box>

      <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
        <Heading size="md">{t('billingInfo.heading')}</Heading>

        <Box bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
          <BookingDetailsStepper step="COMMITMENT" commitmentType={commitmentType} />

          <VStack p={5} spacing={5}>
            {
              isEntityValid ? (
                <EntityCard entity={props.entity} />
              ) : (
                <VStack p={5} spacing={2} justifyContent="center" bg="orange.50" w="100%" borderRadius={8}>
                  <Text fontSize={14} color="orange.500" textAlign="center" maxW={250}>
                    { t('warnings.noEntities.message') }
                  </Text>
                  <EditEntityDrawer entity={props.entity}>
                    <PrimaryButton size="sm" colorScheme="orange">
                      { t('warnings.noEntities.button') }
                    </PrimaryButton>
                  </EditEntityDrawer>
                </VStack>
              )
            }

            { formCard() }

            {
              !isPendingCommitment &&
                <CommitmentMethodLinks
                  commitmentTypes={props.commitmentTypes}
                  commitmentType={commitmentType}
                  onChange={setCommitmentType}
                />
            }
          </VStack>
        </Box>
      </VStack>
    </HStack>
  );
}