import { HStack, Spacer, StackProps } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useState } from 'react';
import { DotHandle } from '../DotHandle';

interface Props {
  children: React.ReactNode
  onRemove(): void;
}

export function RemovableItem({ children, onRemove, ...inheritedProps }: Props & StackProps) {
  const isDraggable = !!inheritedProps.onDragStart;

  const [draggable, setDraggable] = useState(false);

  return (
    <HStack py={1} pr={1} w="100%" spacing={1} draggable={isDraggable && draggable} {...inheritedProps}>
      {
        isDraggable &&
          <DotHandle
            onMouseDown={() => setDraggable(true)}
            onMouseLeave={() => setDraggable(false)}
          />
      }

      <HStack minH={8} w="100%" spacing="10px">
        { children }

        <Spacer />

        <Icon
          icon="squareRoundedX"
          cursor="pointer"
          size={5}
          aria-label="Remove"
          _hover={{ color: 'blackAlpha.700' }}
          onClick={onRemove}
        />
      </HStack>
    </HStack>
  );
}