import { HStack, Text, VStack } from '@chakra-ui/react';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { ApprovalResponseStatus } from './Status/ApprovalResponseStatus';

interface Props {
  user: UserJSON;
  status: ApprovalResponseJSON['status'];
  comment: ApprovalResponseJSON['comment'];
  date: string | null;
}

export function ApprovalStatusCard(props: Props) {
  const { t } = useTranslation('booking');

  const hasDateLabel = props.date && props.status !== 'PENDING';

  const date = hasDateLabel ? formatDate(new Date(props.date), {
    displayTime: true,
    displayTimeZone: true,
    timeZone: getCurrentTimeZone()
  }) : null;

  return (
    <VStack w="100%" spacing={1} pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200" alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize={14} noOfLines={1} fontWeight={700} lineHeight="16px">
          { getUserLabel(props.user) }
        </Text>

        <ApprovalResponseStatus status={props.status} />
      </HStack>

      {
        props.comment &&
          <Text fontSize={14} my={2} fontStyle="italic" lineHeight="16px">
            { props.comment }
          </Text>
      }

      {
        hasDateLabel &&
          <Text fontSize={14} color="gray.400" lineHeight="16px">
            { t(`approvalResponses.${props.status}.dateLabel`, { date }) }
          </Text>
      }
    </VStack>
  );
}