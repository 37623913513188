{
  "header": {
    "title": "Approval requests",
    "tabs": {
      "pending": "Pending requests",
      "processed": "Processed requests"
    }
  },
  "pending": {
    "emptyState": {
      "heading": "You don't have any pending requests at this time",
      "description": "When a booking request needs your approval, it will be displayed on this page and you'll be able to approve or decline it from here."
    }
  },
  "processed": {
    "emptyState": {
      "heading": "You don't have any processed requests at this time",
      "description": "You don't have any for now but the next ones will appear here."
    }
  },
  "details": {
    "billedTo": "Billed to",
    "team": "Team",
    "requestedAt": "Requested at",
    "requestedBy": "Requested by",
    "requestComment": "Request comment"
  },
  "approvalResponse": {
    "form": {
      "comment": {
        "label": "Comment",
        "placeholder": "It's best to give a reason if you reject 🙂"
      }
    },
    "confirmationModal": {
      "approve": {
        "title": "Are you sure you want to approve this request?",
        "message": "Please note: approving this request will automatically book and pay for the event."
      },
      "reject": {
        "title": "Are you sure you want to reject this request?",
        "message": "Please note: rejecting this request will automatically cancel the event."
      }
    },
    "approve": "Approve",
    "reject": "Reject",
    "reviewer": "Reviewer",
    "status": "Status",
    "comment": "Comment",
    "approvedAt": "Approved at",
    "rejectedAt": "Rejected at",
    "canceledAt": "Canceled at"
  },
  "toasts": {
    "approved": {
      "error": "An error occurred while approving the request",
      "success": "Approval request approved"
    },
    "fetchBudgets": {
      "error": "An error occurred while retrieving budgets"
    },
    "rejected": {
      "error": "An error occurred while rejecting the request",
      "success": "Approval request rejected"
    }
  },
  "form": {
    "comment": {
      "placeholder": "Add any useful information for the approver 🙂"
    },
    "requireApproval": {
      "label": "This booking requires an approval of"
    },
    "selfApproval": {
      "label": "As a reviewer, you can approve this booking"
    },
    "awaitingApproval": "Awaiting requests approval…",
    "approve": "Approve this request",
    "submit": "Submit approval request",
    "toasts": {
      "error": "An error occurred while submitting your request",
      "success": "Approval request submitted"
    }
  }
}