import { AddBookingOrganizersBody, CreditCardCommitBody } from '@jurnee/common/src/dtos/bookings';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { Booking, BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingOrganizerJSON } from '@jurnee/common/src/entities/BookingOrganizer';
import { Invoice } from '@jurnee/common/src/entities/Invoice';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { Expand, List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingApi from '../../api/bookings';
import api from '../../api/users/bookings';
import { getBookings } from '../bookings/bookings.thunks';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export interface AddBookingOrganizersPayload {
  bookingId: BookingJSON['id'];
  data: AddBookingOrganizersBody;
}

export interface RemoveBookingOrganizersPayload {
  bookingId: BookingJSON['id'];
  organizerId: UserJSON['id'];
}

export interface CreditCardCommitPayload {
  bookingId: Booking['id'];
  invoiceId: Invoice['id'];
  data: CreditCardCommitBody;
}

export interface QuoteCreatePayload {
  bookingId: Booking['id'];
  data: QuoteCreateBody;
}

export const getBooking = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, Booking['id'], { state: RootState }>('BOOKING_DETAILS_FETCH', async (bookingId, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    const booking = await api.getBooking(user.id, bookingId);

    return booking;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your booking`, status: 'error' }));
  }
});

export const commitByCreditCard = createAsyncThunk<BookingInvoiceJSON, CreditCardCommitPayload, { state: RootState }>('VALIDATE_BOOKING', async (payload, thunkAPI) => {
  try {
    const bookingInvoice = await bookingApi.commitByCreditCard(payload);

    return bookingInvoice;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred during payment`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const cancelBooking = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, BookingJSON, { state: RootState }>('CANCEL_BOOKING', async (booking, thunkAPI) => {
  try {
    const canceledBooking = await bookingApi.cancelBooking(booking.id);

    thunkAPI.dispatch(showToast({ title: `${canceledBooking.data.name} has been successfully canceled`, status: 'success' }));

    const searchParams = new URLSearchParams({ after: Date.now().toString() });
    thunkAPI.dispatch(getBookings({ scope: 'user' , searchParams }));

    return canceledBooking;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while canceling booking`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const addBookingOrganizers = createAsyncThunk<List<BookingOrganizerJSON, never>, AddBookingOrganizersPayload, { state: RootState }>('ADD_BOOKING_ORGANIZERS', async ({ bookingId, data }, thunkAPI) => {
  try {
    const bookingOrganizers = await bookingApi.addBookingOrganizers(bookingId, data);

    return bookingOrganizers;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});

export const removeBookingOrganizer = createAsyncThunk<BookingOrganizerJSON, RemoveBookingOrganizersPayload, { state: RootState }>('REMOVE_BOOKING_ORGANIZER', async (args, thunkAPI) => {
  try {
    const bookingOrganizer = await bookingApi.removeBookingOrganizer(args.bookingId, args.organizerId);

    return bookingOrganizer;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});

export const createQuote = createAsyncThunk<QuoteJSON, QuoteCreatePayload, { state: RootState }>('CREATE_QUOTE', async ({ bookingId, data }, thunkAPI) => {
  try {
    const quote = await bookingApi.createQuote(bookingId, data);

    const searchParams = new URLSearchParams({ after: Date.now().toString() });
    thunkAPI.dispatch(getBookings({ scope: 'user' , searchParams }));

    return quote;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});