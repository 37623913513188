import { UserPaymentProfilesJSON } from '@jurnee/common/src/entities/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/user';
import { RootState } from '../state';

export const getUserPaymentProfilesThunk = createAsyncThunk<UserPaymentProfilesJSON, null, { state: RootState }>('USER_PAYMENT_PROFILES_FETCH', async (_, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    const userPaymentProfiles = await api.getUserPaymentProfiles(user.id);
    return userPaymentProfiles;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});
