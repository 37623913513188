import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { hasInitialApprovalResponses } from '@jurnee/common/src/utils/approvalRequests';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  approvalRequest: ApprovalRequestJSON | null;
  isSelfApprovalEnabled: boolean;
  processNumber: BookingJSON['processNumber'];
  disabled: boolean;
  isProcessNumberRequired: boolean;
  onSubmit(): Promise<void>;
}

export function SubmitButton(props: Props) {
  const { t } = useTranslation('approvalRequests');

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSubmit() {
    setIsSubmitting(true);
    await props.onSubmit();
    setIsSubmitting(false);
  };

  if (props.approvalRequest && hasInitialApprovalResponses(props.approvalRequest)) {
    return <AwaitingInfoCard label={t('form.awaitingApproval')} />;
  }

  if (props.isSelfApprovalEnabled) {
    return (
      <ConfirmModal
        buttonColor="teal"
        title={t('approvalResponse.confirmationModal.approve.title')}
        message={t('approvalResponse.confirmationModal.approve.message')}
        buttonLabel={t('approvalResponse.approve')}
        onConfirm={props.onSubmit}
      >
        <PrimaryButton
          w="100%"
          size="sm"
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={props.isProcessNumberRequired && !props.processNumber}
        >
          { t('form.approve') }
        </PrimaryButton>
      </ConfirmModal>
    );
  }

  return (
    <PrimaryButton
      w="100%"
      size="sm"
      type="submit"
      colorScheme='blue'
      onClick={onSubmit}
      isLoading={isSubmitting}
      isDisabled={props.disabled}
    >
      { t('form.submit') }
    </PrimaryButton>
  );
}