import { api } from '@jurnee/common/src/browser/Api';
import { ApprovalProcessUpsertBody } from '@jurnee/common/src/dtos/approvalProcesses';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { List } from '@jurnee/common/src/serializers';

export default {
  getApprovalProcesses(): Promise<List<ApprovalProcessJSON, never>> {
    return api.get('approval-processes');
  },
  createApprovalProcess(data: ApprovalProcessUpsertBody): Promise<ApprovalProcessJSON> {
    return api.post('approval-processes', data);
  },
  updateApprovalProcess(data: ApprovalProcessUpsertBody, id: ApprovalProcessJSON['id']): Promise<ApprovalProcessJSON> {
    return api.put(`approval-processes/${id}`, data);
  },
  deleteApprovalProcess(id: ApprovalProcessJSON['id']): Promise<ApprovalProcessJSON> {
    return api.delete(`approval-processes/${id}`);
  }
};