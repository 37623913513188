import { api } from '@jurnee/common/src/browser/Api';
import { PaymentProfilesUpsertBody } from '@jurnee/common/src/dtos/paymentProfiles';
import { PaymentProfileJSON, PaymentProfileRelationshipsJSON } from '@jurnee/common/src/entities/PaymentProfile';
import { List } from '@jurnee/common/src/serializers';

export function getPaymentProfiles(): Promise<List<PaymentProfileJSON, PaymentProfileRelationshipsJSON>> {
  return api.get('payment-profiles');
}

export function upsertPaymentProfiles(data: PaymentProfilesUpsertBody): Promise<List<PaymentProfileJSON, PaymentProfileRelationshipsJSON>> {
  return api.put('payment-profiles', data);
}