import { Box, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  label: string;
  processNumber: string;
}

export default class ProcessNumber extends React.PureComponent<Props> {

  render() {
    return (
      <VStack w="100%" alignItems="flex-start" spacing="10px">
        <Text fontSize={14} fontWeight={700} lineHeight="16px">{this.props.label}</Text>

        <Box w="100%" pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200">
          <Text fontSize={14} color="gray.400" lineHeight="16px">{this.props.processNumber}</Text>
        </Box>
      </VStack>
    );
  }

}