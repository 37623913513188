import { Divider, VStack } from '@chakra-ui/react';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { ApprovalResponseCard } from 'src/pages/ApprovalRequests/ApprovalRequestCard/ApprovalResponsesList/ApprovalResponseCard';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';

interface Props {
  approvalRequest: ApprovalRequestData;
}

export function ApprovalResponsesList({ approvalRequest }: Props) {
  return (
    <VStack p={5} spacing={5}>
      {
        approvalRequest.approvalResponses.map((approvalResponse: ApprovalResponseJSON, i) => {
          const user = approvalRequest.users.find(({ id }) => id === approvalResponse.userId);

          return (
            <VStack key={i} w="100%" spacing={4}>
              { i > 0 && <Divider /> }
              <ApprovalResponseCard
                approvalResponse={approvalResponse}
                user={user}
              />
            </VStack>
          );
        })
      }
    </VStack>
  );
}