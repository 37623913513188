import { Box, Center, Text } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserLabel, sortByUserLabel } from '@jurnee/common/src/utils/user';
import { DragEvent, useMemo, useState } from 'react';
import { RemovableItemsList } from 'src/components/RemovableItemsList';
import { RemovableItem } from 'src/components/RemovableItemsList/RemovableItem';

interface Props {
  users: UserDetails[];
  userIds: number[];
  onRemove(userId: number): void;
  onOrderChange?(userIds: number[]): void;
}

function getStyleProps(index: number, draggedIndex: number, dropTargetIndex: number) {
  if (draggedIndex === null) {
    return {};
  }

  if (index === draggedIndex) {
    return {
      opacity: '30%'
    };
  }

  if (index === dropTargetIndex && index < draggedIndex) {
    return {
      boxShadow: 'inset 0 2px 0 var(--chakra-colors-gray-400)'
    };
  }

  if (index === dropTargetIndex && index > draggedIndex) {
    return {
      boxShadow: 'inset 0 -2px 0 var(--chakra-colors-gray-400)'
    };
  }

  return {};
}

export function UsersList(props: Props) {
  const isDraggable = !!props.onOrderChange;

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);

  const users = useMemo(() => {
    const users = props.userIds.map(userId => props.users.find(({ id }) => userId === id));
    return isDraggable ? users : sortByUserLabel(users);
  },
  [props.users, props.userIds, isDraggable]);

  function onDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function onDragEnd() {
    const newIds = [...props.userIds];
    const draggedUser = newIds[draggedIndex];

    newIds.splice(draggedIndex, 1);
    newIds.splice(dropTargetIndex, 0, draggedUser);

    setDraggedIndex(null);
    setDropTargetIndex(null);

    props.onOrderChange(newIds);
  }

  return (
    <RemovableItemsList mt="10px" {...isDraggable && { paddingLeft: 1 }}>
      {
        users.map((user, i) => {
          const draggableProps = {
            onDragStart: () => setDraggedIndex(i),
            onDragEnter: () => setDropTargetIndex(i),
            onDragOver,
            onDragEnd,
          };

          const styleProps = getStyleProps(i, draggedIndex, dropTargetIndex);

          return <RemovableItem
            key={user.id}
            onRemove={() => props.onRemove(user.id)}
            {...isDraggable && { ...draggableProps, ...styleProps }}
          >
            <Box borderRadius={4} position="relative" overflow="hidden">
              <Avatar user={user} borderRadius={0} />
              {
                isDraggable &&
                  <Center
                    position="absolute"
                    w="100%"
                    h="100%"
                    top={0}
                    bgColor="rgba(0, 0, 0, 0.6)"
                  >
                    <Text color="white" fontWeight={700} lineHeight={14}>{i + 1}</Text>
                  </Center>
              }
            </Box>
            <Text>{getUserLabel(user)}</Text>
          </RemovableItem>;
        })
      }
    </RemovableItemsList>
  );
}