import { HStack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommitmentType } from 'src/utils/booking';

interface Props {
  commitmentTypes: CommitmentType[];
  commitmentType: CommitmentType;
  onChange(commitmentType: CommitmentType): void;
}

export function CommitmentMethodLinks({ commitmentTypes, commitmentType, onChange }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'steps.commitment.switch' });

  const switchableTypes = useMemo(() => {
    return commitmentTypes.filter(type => type !== commitmentType);
  }, [commitmentTypes, commitmentType]);

  if (switchableTypes.length === 0) {
    return;
  }

  return (
    <HStack w="100%" justifyContent={switchableTypes.length === 1 ? 'center' : 'space-between'}>
      {
        switchableTypes.map((type, i) =>
          <Text
            key={i}
            color="blue.400"
            cursor="pointer"
            _hover={{ color: 'blue.600' }}
            lineHeight="14px"
            onClick={() => onChange(type)}
          >
            { t(type) }
          </Text>
        )
      }
    </HStack>
  );
}