import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function ErrorCard() {
  const { t } = useTranslation('settings', { keyPrefix: 'paymentProfiles' });

  return (
    <VStack
      alignItems="flex-start"
      bgColor="white"
      p={5}
      border="1px solid"
      color="red.500"
      borderColor="red.500"
      borderRadius={4}
      spacing={1}
    >
      <Text>{ t('errorCard.description') }</Text>

      <ul>
        <li>{t('errorCard.creditCard')}</li>
        <li>{t('errorCard.quote')}</li>
        <li>{t('errorCard.approval')}</li>
      </ul>
    </VStack>
  );
}