import { DEFAULT_UTC_TIMEZONE } from '../entities/Address';
import PropositionEntity, { PropositionJSON } from '../entities/Proposition';
import PropositionsGroupEntity, { PropositionsGroupJSON } from '../entities/PropositionsGroup';

export function isPropositionsGroupDraft({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'DRAFT';
}

export function isPropositionsGroupOpen({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'OPEN';
}

export function isPropositionsGroupClosed({ status }: Pick<PropositionsGroupEntity, 'status'>): boolean {
  return status === 'CLOSED';
}

export function hasAPropositionsGroupOpen(propositionsGroups: (PropositionsGroupEntity | PropositionsGroupJSON)[]) {
  return propositionsGroups.some(isPropositionsGroupOpen);
}

export function getPropositionsGroupTimezone(propositionsGroup: PropositionsGroupJSON) {
  return propositionsGroup.address?.timezone || DEFAULT_UTC_TIMEZONE;
}

export function getPropositionsByPropositionsGroupId<P extends PropositionEntity | PropositionJSON>(propositions: P[], propositionsGroupId: PropositionsGroupEntity['id']) {
  return propositions.filter(proposition =>
    proposition.propositionsGroupId === propositionsGroupId
  );
}

export function hasAPropositionsGroupWaitingPropososals(propositionsGroups: (PropositionsGroupEntity | PropositionsGroupJSON)[], propositions: (PropositionEntity | PropositionJSON)[]) {
  return propositionsGroups.some(propositionsGroup => {
    const filteredPropositions = getPropositionsByPropositionsGroupId(propositions, propositionsGroup.id);

    if (isPropositionsGroupClosed(propositionsGroup)) {
      return false;
    }

    if (isPropositionsGroupOpen(propositionsGroup)) {
      return filteredPropositions.length > 0 && filteredPropositions.every(({ declinedAt }) => !!declinedAt);
    }

    return true;
  });
}