import { Box, BoxProps, HStack, Text, TextProps, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BookingStep, CommitmentType } from 'src/utils/booking';

interface Props {
  step: BookingStep;
  commitmentType: CommitmentType;
}

const colors: Record<Exclude<BookingStep, 'WAITING_EVENT' | 'FINISHED'>, (step: BookingStep) => string> = {
  PLANNING: (step) => step === 'PLANNING' ? 'blue.400' : 'teal.400',
  COMMITMENT: (step) => step === 'PLANNING' ? 'gray.400' : step === 'COMMITMENT' ? 'blue.400' : 'teal.400',
  CONFIRMATION: (step) => ['WAITING_EVENT', 'FINISHED'].includes(step) ? 'teal.400' : step === 'CONFIRMATION' ? 'blue.400' : 'gray.400'
};

function StepLabel({ content, color, textAlign }: TextProps) {
  return (
    <Text w="100px" fontSize="12px" fontWeight={500} textTransform="uppercase" color={color} textAlign={textAlign}>
      {content}
    </Text>
  );
}

function StepsLabels({ step, commitmentType }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'steps' });

  return (
    <HStack w="100%" justifyContent="space-between" alignSelf="center">
      <StepLabel content={t('planning.label')} color={colors['PLANNING'](step)} textAlign="left" />
      <StepLabel content={t(`commitment.types.${commitmentType ?? 'CREDIT_CARD'}`)} color={colors['COMMITMENT'](step)} textAlign="center" />
      <StepLabel content={t('confirmation.label')} color={colors['CONFIRMATION'](step)} textAlign="right" />
    </HStack>
  );
}

function StepDot({ bg }: BoxProps) {
  return (
    <Box minW="6px" h="6px" borderRadius={3} bg={bg} />
  );
}

function StepsDots({ step }: Props) {
  return (
    <HStack w="100%" justifyContent="space-between" alignSelf="center">
      <StepDot bg={colors['PLANNING'](step)} />
      <Box h="1px" bg="gray.200" w="50%" />
      <StepDot bg={colors['COMMITMENT'](step)} />
      <Box h="1px" bg="gray.200" w="50%" />
      <StepDot bg={colors['CONFIRMATION'](step)} />
    </HStack>
  );
}

export function BookingDetailsStepper(props: Props) {
  return (
    <VStack w="100%" spacing={1} p={5} borderBottom="1px solid" borderColor="gray.200">
      <StepsLabels {...props} />
      <StepsDots {...props} />
    </VStack>
  );
}