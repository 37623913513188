import { Text, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { isBookingProcessing } from '@jurnee/common/src/utils/bookings';
import { useTranslation } from 'react-i18next';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  booking: BookingJSON;
  onNext(): void;
}

export function SubmitSection(props: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'steps.planning.form' });

  if (isBookingProcessing(props.booking)) {
    return (
      <VStack spacing={4}>
        <AwaitingInfoCard label={t('workingOnIt')} />

        <Text fontSize={14} color='gray.400' textAlign="center">
          { t('workingOnItInfo') }
        </Text>
      </VStack>
    );
  }

  return (
    <VStack w="100%" spacing={4}>
      <PrimaryButton w="100%" size="sm" onClick={props.onNext}>
        { t('submit') }
      </PrimaryButton>

      <Text fontSize={14} lineHeight="16px" color="gray.400" textAlign="center">
        { t('notChargedYet') }
      </Text>
    </VStack>
  );
}