import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { CompanyUpdateBody } from '@jurnee/common/src/dtos/companies';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import router from 'src/router';
import { useAppDispatch } from 'src/store';
import { getCompanySelector } from 'src/store/company/company.selector';
import { updateCompany } from 'src/store/company/company.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import Sso from '../Sso';
import Approval from './Approval';
import { Budgets } from './Budgets';
import Catalog from './Catalog';
import { Integrations } from './Integrations';
import { PaymentProfiles } from './PaymentProfiles';

interface HeaderProps {
  tabs: { label: string; key: string; }[];
}

function Header({ tabs }: HeaderProps) {
  const { t } = useTranslation('settings');

  return (
    <Box bg="white" w="100%">
      <Heading p={8}>{t('header.title')}</Heading>

      <TabList>
        { tabs.map(tab => (<Tab key={tab.key}>{tab.label}</Tab>)) }
      </TabList>
    </Box>
  );
}

export function CompanySettings() {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation('settings', { keyPrefix: 'header' });

  const user = useSelector(getUserSelector);
  const company = useSelector(getCompanySelector);

  const [isLoading, setIsLoading] = useState(false);

  const tabs = [{
    label: `${t('tabs.budgets')}`,
    key: 'budgets'
  }, {
    label: `${t('tabs.paymentProfiles')}`,
    key: 'paymentProfiles'
  }, {
    label: `${t('tabs.catalog')}`,
    key: 'catalog'
  }, {
    label: `${t('tabs.approval')}`,
    key: 'approval'
  }, {
    label: `${t('tabs.sso')}`,
    key: 'sso'
  }, {
    label: `${t('tabs.integrations')}`,
    key: 'integrations'
  }];

  async function onUpdateCompany(data: CompanyUpdateBody) {
    setIsLoading(true);

    dispatch(updateCompany(data));

    setIsLoading(false);
  }

  if (!isAdmin(user)) {
    return <Navigate to='/' />;
  }

  return (
    <main>
      <Tabs w="100%" defaultIndex={getDefaultTabIndex(tabs, router.state.location.search)} onChange={index => setSearchParams({ tab: tabs[index].key })} isLazy={true}>
        <Header tabs={tabs} />
        <TabPanels>
          <TabPanel>
            <Budgets />
          </TabPanel>
          <TabPanel>
            <PaymentProfiles />
          </TabPanel>
          <TabPanel>
            <Catalog
              loading={isLoading}
              company={company}
              onSave={onUpdateCompany}
            />
          </TabPanel>
          <TabPanel>
            <Approval />
          </TabPanel>
          <TabPanel>
            <Sso />
          </TabPanel>
          <TabPanel>
            <Integrations />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </main>
  );
}