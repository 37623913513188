import { Text } from '@chakra-ui/react';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useTranslation } from 'react-i18next';
import { RemovableItemsList } from 'src/components/RemovableItemsList';
import EmptyStateList from 'src/components/RemovableItemsList/EmptyStateList';
import { RemovableItem } from 'src/components/RemovableItemsList/RemovableItem';

interface Props {
  teams: TeamJSON[];
  teamIds: number[];
  onRemoveTeam(user: TeamJSON): void;
}

export function RemovableTeamsList({ teams, teamIds, onRemoveTeam }: Props) {
  const { t } = useTranslation('employees', { keyPrefix: 'drawer.form.teams' });

  if (teamIds.length === 0) {
    return <EmptyStateList description={t('noTeams')} />;
  }

  const removableTeams = teamIds.map(teamId => {
    return teams.find(({ id }) => teamId === id);
  });

  return (
    <RemovableItemsList>
      {
        sortAlphabeticallyBy(removableTeams, 'name').map(team => (
          <RemovableItem key={team.id} onRemove={() => onRemoveTeam(team)}>
            <Text pl={1}>{team.name}</Text>
          </RemovableItem>
        ))
      }
    </RemovableItemsList>
  );
}