import ApprovalRequestEntity, { ApprovalRequestJSON } from '../entities/ApprovalRequest';
import { BookingExport } from '../entities/Booking';
import BookingInvoiceEntity, { BookingInvoice, BookingInvoiceEntityWithSetupIntent, BookingInvoiceJSON, BookingsInvoicesSearchParams, BookingsInvoicesSearchQuery } from '../entities/BookingInvoice';
import { Currency } from '../entities/Currency';
import QuoteEntity, { QuoteJSON } from '../entities/Quote';
import { hasAnApprovalRequestApproved } from './approvalRequests';
import { DateRange, isInDateRange } from './dates';

export function getBookingInvoiceTotal(bookingInvoice: BookingInvoice | BookingInvoiceJSON): number {
  return (bookingInvoice.subtotal - bookingInvoice.totalDiscount) + bookingInvoice.totalTax;
}

export function hasSetupIntent(bookingInvoice: Pick<BookingInvoiceEntity, 'providerSetupIntentId'>): bookingInvoice is BookingInvoiceEntityWithSetupIntent {
  return typeof bookingInvoice.providerSetupIntentId === 'string';
}

export function hasQuotePending(quotes: Pick<QuoteEntity, 'status'>[]) {
  return quotes.some(quote => quote.status === 'INITIAL');
}

export function hasQuoteSigned(quotes: Pick<QuoteEntity, 'status' | 'acceptedAt'>[], range?: DateRange): boolean {
  return quotes.some(({ status, acceptedAt }) => {
    const inRange = range ? isInDateRange(acceptedAt, range) : true;

    return status === 'SIGNED' && inRange;
  });
}

export function hasInvoicePaidOrQuoteSigned(bookingsInvoices: BookingInvoiceJSON[], quotes: QuoteJSON[]) {
  return bookingsInvoices.some(({ providerInvoiceId, status }) => {
    const invoiceQuotes = quotes.filter(quote => quote.providerInvoiceId === providerInvoiceId);
    return status === 'PAID' || hasQuoteSigned(invoiceQuotes);
  });
}

export function getBookingsInvoicesToPay(bookingsInvoices: BookingInvoiceJSON[], quotes: Pick<QuoteEntity, 'providerInvoiceId' | 'status' | 'acceptedAt'>[]) {
  return bookingsInvoices.filter(bookingInvoice => {
    const invoiceQuotes = quotes.filter(quote => quote.providerInvoiceId === bookingInvoice.providerInvoiceId);
    return bookingInvoice.status !== 'PAID' && !bookingInvoice.providerSetupIntentId && !hasQuoteSigned(invoiceQuotes);
  });
}

type TotalDetails = {
  subtotal: BookingInvoiceJSON['subtotal'];
  totalDiscount: BookingInvoiceJSON['totalDiscount'];
  totalTax: BookingInvoiceJSON['totalTax'];
  total: number;
  currency: Currency;
}

export function getBookingInvoicesTotalDetails(bookingsInvoices: Pick<BookingInvoiceEntity, 'subtotal' | 'totalDiscount' | 'totalTax' | 'currency'>[]): TotalDetails {
  return bookingsInvoices.reduce<TotalDetails>((priceDetails, bookingInvoice) => {
    const total = (bookingInvoice.subtotal - bookingInvoice.totalDiscount) + bookingInvoice.totalTax;

    return {
      subtotal: priceDetails.subtotal + bookingInvoice.subtotal,
      totalDiscount: priceDetails.totalDiscount + bookingInvoice.totalDiscount,
      totalTax: priceDetails.totalTax + bookingInvoice.totalTax,
      total: priceDetails.total + total,
      currency: bookingInvoice.currency
    };
  }, {
    subtotal: 0,
    totalDiscount: 0,
    totalTax: 0,
    total: 0,
    currency: 'EUR'
  });
}

export function getInvoicesTotal(bookingsInvoices: Pick<BookingInvoice, 'subtotal' | 'totalDiscount'>[]): number {
  return bookingsInvoices.reduce((total, { subtotal, totalDiscount }) => {
    const totalHt = subtotal - totalDiscount;

    return total + totalHt;
  }, 0);
}

export function isFinalized({ status, finalizedAt }: Pick<BookingInvoiceEntity, 'status' | 'finalizedAt'>, range?: DateRange): boolean {
  const inRange = range ? isInDateRange(finalizedAt, range) : true;

  return ['PAID', 'OPEN'].includes(status) && inRange;
}

export function isPending({ status, quotes, approvalRequests }: BookingInvoiceEntity): boolean {
  if (status !== 'DRAFT') {
    return false;
  }

  if (hasQuoteSigned(quotes)) {
    return false;
  }

  if (hasAnApprovalRequestApproved(approvalRequests)) {
    return false;
  }

  return true;
}

export function hasCommitment(bookingInvoice: Pick<BookingInvoiceEntity | BookingInvoiceJSON, 'finalizedAt' | 'status'>, quotes: (QuoteEntity | QuoteJSON)[], approvalRequests: (ApprovalRequestEntity | ApprovalRequestJSON)[], range?: DateRange) {
  if (isFinalized(bookingInvoice, range)) {
    return true;
  }

  if (hasQuoteSigned(quotes, range)) {
    return true;
  }

  if (hasAnApprovalRequestApproved(approvalRequests, range)) {
    return true;
  }

  return false;
}

export function getPendingInvoices(bookingsInvoices: BookingInvoiceEntity[]): BookingInvoiceEntity[] {
  return bookingsInvoices.filter(isPending);
}

export function getCommittedInvoices(bookingsInvoices: BookingInvoiceEntity[], range: DateRange): BookingInvoiceEntity[] {
  return bookingsInvoices.filter((invoice) => {
    return hasCommitment(invoice, invoice.quotes, invoice.approvalRequests, range);
  });
}

export function buildBookingsInvoicesSearchParams(query: BookingsInvoicesSearchQuery): BookingsInvoicesSearchParams {
  const params: BookingsInvoicesSearchParams = {};

  if (Array.isArray(query.budgetIds) && query.budgetIds.length > 0) {
    params.budgetIds = query.budgetIds.filter(budgetId => !isNaN(Number(budgetId))).map(budgetId => Number(budgetId));
  }

  if (Array.isArray(query.entityIds) && query.entityIds.length > 0) {
    params.entityIds = query.entityIds.filter(entityId => !isNaN(Number(entityId))).map(entityId => Number(entityId));
  }

  if (Array.isArray(query.teamIds) && query.teamIds.length > 0) {
    params.teamIds = query.teamIds.filter(teamId => !isNaN(Number(teamId))).map(teamId => Number(teamId));
  }

  if (query.from) {
    params.from = Number(query.from);
  }

  if (query.to) {
    params.to = Number(query.to);
  }

  return params;
}

export function haveAllASetupIntent(bookingsInvoices: (BookingInvoiceJSON | BookingInvoiceEntity)[]) {
  if (bookingsInvoices.length === 0) {
    return false;
  }

  return bookingsInvoices.every(bookingInvoice => hasSetupIntent(bookingInvoice));
}

export function areAllBookingsInvoicesPaidByCard(bookingsInvoices: BookingExport['bookingsInvoices']) {
  return bookingsInvoices.every(({ providerSetupIntentId, status }) => {
    return status === 'PAID' && typeof providerSetupIntentId === 'string';
  });
}

export function getInvoicingDetails(bookingsInvoices: BookingInvoice[]) {
  return bookingsInvoices.reduce((details, bookingInvoice) => {
    const total = (bookingInvoice.subtotal - bookingInvoice.totalDiscount) + bookingInvoice.totalTax;

    return {
      subtotal: details.subtotal + bookingInvoice.subtotal,
      totalDiscount: details.totalDiscount + bookingInvoice.totalDiscount,
      totalExclTax: details.totalExclTax + (bookingInvoice.subtotal - bookingInvoice.totalDiscount),
      totalTax: details.totalTax + bookingInvoice.totalTax,
      total: details.total + total
    };
  }, {
    subtotal: 0,
    totalDiscount: 0,
    totalExclTax: 0,
    totalTax: 0,
    total: 0
  });
}

export function getBookingInvoiceDateFromStatus(bookingInvoice: BookingInvoiceJSON) {
  switch(bookingInvoice.status) {
  case 'OPEN':
    return new Date(bookingInvoice.finalizedAt as string);
  case 'PAID':
    return new Date(bookingInvoice.paidAt as string);
  default:
    return new Date(bookingInvoice.createdAt);
  }
}