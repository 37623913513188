import { VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { updateCompany } from 'src/store/company/company.thunks';
import { areProviderDiscountsFetched, getProviderDiscountsSelector } from 'src/store/providerDiscounts/providerDiscounts.selectors';
import { addProviderDiscount, getProviderDiscounts } from 'src/store/providerDiscounts/providerDiscounts.thunks';
import { Discount } from './Discount';
import { InvoiceForward } from './InvoiceForward';

export function Configuration() {
  const dispatch = useAppDispatch();

  const providerDiscounts = useSelector(getProviderDiscountsSelector);
  const isFetched = useSelector(areProviderDiscountsFetched);

  const providerDiscount = useMemo(() => providerDiscounts.length > 0 ? providerDiscounts[0] : null, [providerDiscounts]);

  useEffect(() => {
    dispatch(getProviderDiscounts());
  }, []);

  function onAddDiscountCode(entityId: ProviderDiscountJSON['entityId'], coupon: string) {
    dispatch(addProviderDiscount({ entityId, body: { coupon } }));
    dispatch(trackEvent({ name: 'added_discount_code', properties: { coupon } }));
  };

  function onSaveBillingEmails(billingEmails: CompanyJSON['billingEmails']) {
    dispatch(updateCompany({ billingEmails }));
  };

  if (!isFetched) {
    return <Loader h={400} />;
  }

  return (
    <VStack
      w="100%"
      maxW="572px"
      p={5}
      spacing={5}
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      alignItems="flex-start"
      justifySelf="center"
    >
      <Discount providerDiscount={providerDiscount} onApply={onAddDiscountCode} />
      <InvoiceForward onSave={onSaveBillingEmails} />
    </VStack>
  );
}