import { Center, Text } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  label: string;
}

export default class AwaitingInfoCard extends React.PureComponent<Props> {

  render() {
    return (
      <Center w="100%" height="32px" bgColor="gray.400" borderRadius={4}>
        <Text color="white" fontSize={14} fontWeight={500}>{this.props.label}</Text>
      </Center>
    );
  }

}