import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import { EmailCreatableSelector } from 'src/components/EmailCreatableSelector';
import { getCompanySelector } from 'src/store/company/company.selector';

interface Props {
  onSave(emails: CompanyJSON['billingEmails']): void;
}

export function InvoiceForward({ onSave }: Props) {
  const { t } = useTranslation(['billing', 'common']);

  const company = useSelector(getCompanySelector);

  const [emails, setEmails] = useState(company.billingEmails);

  return (
    <VStack w="100%" spacing="10px" alignItems="flex-start">
      <Text fontSize={16} lineHeight="16px" fontWeight={700} >
        { t('configuration.invoiceForward.label') }
      </Text>

      <Text lineHeight="14px" color="gray.400">{t('configuration.invoiceForward.helpText')}</Text>

      <HStack w="100%" spacing={5}>
        <Box w="100%" className="react-multi-creatable-select-small">
          <EmailCreatableSelector emails={emails} onChange={setEmails} />
        </Box>

        <PrimaryButton size="sm" colorScheme="black" onClick={() => onSave(emails)}>
          { t('common:buttons.save') }
        </PrimaryButton>
      </HStack>
    </VStack>
  );
}