import { Checkbox, Divider, Heading, HStack, Select, Text, useToast, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { PaymentProfileUpsertBody } from '@jurnee/common/src/dtos/paymentProfiles';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { PaymentProfileJSON, PaymentProfileRelationshipsJSON } from '@jurnee/common/src/entities/PaymentProfile';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { List } from '@jurnee/common/src/serializers';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getPaymentProfileData } from '@jurnee/common/src/utils/paymentProfiles';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import { RemovableUsersList } from 'src/components/RemovableUsersList';
import { UsersSearchSelect } from 'src/components/UsersSearchSelect';
import { useAppDispatch } from 'src/store';
import { getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { getEmployees } from 'src/store/employees/employees.thunks';
import api from '../../../../api/approvalProcesses';
import { getPaymentProfiles, upsertPaymentProfiles } from '../../../../api/paymentProfiles';
import { ErrorCard } from './ErrorCard';
import { ProfileSwitch } from './ProfileSwitch';
import { Label } from './ProfileSwitch/Label';

export function PaymentProfiles() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['settings', 'common']);

  const [approvalProcesses, setApprovalProcesses] = useState<ApprovalProcessJSON[]>([]);
  const [arePaymentProfilesLoading, setArePaymentProfilesLoading] = useState(true);
  const [areApprovalProcessesLoading, setAreApprovalProcessesLoading] = useState(true);
  const employees = useSelector(getEmployeesSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const isLoading = arePaymentProfilesLoading || areApprovalProcessesLoading || employeesFetchStatus !== 'FETCHED';

  const [quote, setQuote] = useState<PaymentProfileUpsertBody>(null);
  const [approvalProcess, setApprovalProcess] = useState<PaymentProfileUpsertBody>(null);
  const [creditCard, setCreditCard] = useState<PaymentProfileUpsertBody>(null);
  const [isCreditCardRestricted, setIsCreditCardRestricted] = useState(false);

  const isValidConfig = useMemo(() => {
    if (quote?.status === 'ENABLED') {
      return true;
    }

    if (creditCard?.status === 'ENABLED') {
      return true;
    }

    if (approvalProcess?.status === 'ENABLED' && !!approvalProcess?.approvalProcessId) {
      return true;
    }

    return false;
  }, [isLoading, quote, approvalProcess, creditCard]);

  const isSaveDisabled = isLoading || !isValidConfig;
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const filteredApprovalProcesses = useMemo(() => {
    return sortAlphabeticallyBy(approvalProcesses, 'name')
      .filter(({ approvalRules }) => approvalRules.length > 0);
  }, [approvalProcesses]);

  function updateState({ list, relationships: { usersPaymentProfiles }}: List<PaymentProfileJSON, PaymentProfileRelationshipsJSON>) {
    const quote = getPaymentProfileData(list, usersPaymentProfiles, 'QUOTE');
    setQuote(quote);
    const approvalProcess = getPaymentProfileData(list, usersPaymentProfiles, 'APPROVAL_PROCESS');
    setApprovalProcess(approvalProcess);
    const creditCard = getPaymentProfileData(list, usersPaymentProfiles, 'CREDIT_CARD');
    setCreditCard(creditCard);
    setIsCreditCardRestricted(creditCard.status === 'RESTRICTED');
  }

  async function fetchPaymentProfiles() {
    try {
      const data = await getPaymentProfiles();
      updateState(data);
      setArePaymentProfilesLoading(false);
    } catch(e) {
      toast(getErrorToast(t('paymentProfiles.toasts.fetchPaymentProfiles.error')));
    }
  }

  async function fetchApprovalProcesses() {
    try {
      const { list } = await api.getApprovalProcesses();
      setApprovalProcesses(list);
      setAreApprovalProcessesLoading(false);
    } catch(e) {
      toast(getErrorToast(t('paymentProfiles.toasts.fetchApprovalProcesses.error')));
    }
  }

  useEffect(() => {
    fetchPaymentProfiles();
    fetchApprovalProcesses();

    if (employeesFetchStatus === 'INITIAL') {
      dispatch(getEmployees());
    }
  }, []);

  function onSwitch(type: PaymentProfileJSON['type'], checked: boolean) {
    if (type === 'APPROVAL_PROCESS') {
      return setApprovalProcess({ ...approvalProcess, status: checked ? 'ENABLED' : 'DISABLED' });
    }

    if (type === 'CREDIT_CARD') {
      return setCreditCard({ ...creditCard, status: !checked ? 'DISABLED' : isCreditCardRestricted ? 'RESTRICTED' : 'ENABLED' });
    }

    setQuote({ ...quote, status: checked ? 'ENABLED' : 'DISABLED' });
  }

  function onCreditCardRestrictedChange({ target }: ChangeEvent<HTMLInputElement>) {
    setIsCreditCardRestricted(target.checked);
    setCreditCard({ ...creditCard, status: target.checked ? 'RESTRICTED' : 'ENABLED' });
  }

  function onApprovalProcessChange({ target }: ChangeEvent<HTMLSelectElement>) {
    const approvalProcessId = target.value ? Number(target.value) : null;
    setApprovalProcess({ ...approvalProcess, approvalProcessId });
  }

  function onAddUser({ value: { id } }: { value: UserDetails }) {
    setCreditCard({ ...creditCard, userIds: [...creditCard.userIds, id]});
  }

  function onRemoveUser({ id }: UserDetails) {
    setCreditCard({ ...creditCard, userIds: creditCard.userIds.filter(userId => userId !== id) });
  }

  async function onSave() {
    setIsSaving(true);
    setIsSubmitted(true);

    if (creditCard.status === 'RESTRICTED' && creditCard.userIds.length === 0) {
      setIsSaving(false);
      return;
    }

    try {
      const data = await upsertPaymentProfiles({
        data: [
          quote,
          {
            ...approvalProcess,
            approvalProcessId: approvalProcess.status === 'ENABLED' ? approvalProcess.approvalProcessId : null
          },
          {
            ...creditCard,
            userIds: creditCard.status === 'RESTRICTED' ? creditCard.userIds : []
          },
        ]
      });

      updateState(data);

      toast(getSuccessToast(t('paymentProfiles.toasts.upsert.success')));
    } catch(e) {
      toast(getErrorToast(t('paymentProfiles.toasts.upsert.error')));
    }

    setIsSaving(false);
    setIsSubmitted(false);
  }

  if (isLoading) {
    return <Loader h={400} />;
  }

  return (
    <VStack w="100%" maxW="572px" justifySelf="center" spacing={5}>
      { !isValidConfig && <ErrorCard /> }

      <VStack
        w="100%"
        spacing={4}
        p={5}
        bgColor="white"
        border="1px solid"
        borderColor="gray.200"
        borderRadius={4}
        alignItems="flex-start"
      >
        <Heading size="md" lineHeight="20px">{t('paymentProfiles.wireTransfer.heading')}</Heading>
        <Text color="gray.400">{t('paymentProfiles.wireTransfer.description')}</Text>

        <ProfileSwitch
          type="QUOTE"
          checked={quote.status === 'ENABLED'}
          onChange={checked => onSwitch('QUOTE', checked)}
        />

        <ProfileSwitch
          type="APPROVAL_PROCESS"
          checked={approvalProcess.status === 'ENABLED'}
          onChange={checked => onSwitch('APPROVAL_PROCESS', checked)}
        >
          <VStack w="100%" alignItems="flex-start" spacing={1}>
            <Label
              label={t('paymentProfiles.forms.APPROVAL_PROCESS.default.label')}
              helperText={t('paymentProfiles.forms.APPROVAL_PROCESS.default.helperText')}
            />
            <Select
              size="sm"
              value={`${approvalProcess.approvalProcessId}`}
              onChange={onApprovalProcessChange}
              placeholder={t('paymentProfiles.forms.APPROVAL_PROCESS.default.placeholder')}
            >
              {
                filteredApprovalProcesses.map(({ id, name }) =>
                  <option key={id} value={id}>{name}</option>
                )
              }
            </Select>
          </VStack>
        </ProfileSwitch>

        <Divider />

        <Heading size="md" lineHeight="20px">{t('paymentProfiles.creditCard.heading')}</Heading>
        <Text color="gray.400">{t('paymentProfiles.creditCard.description')}</Text>

        <ProfileSwitch
          type="CREDIT_CARD"
          checked={['ENABLED', 'RESTRICTED'].includes(creditCard.status)}
          onChange={checked => onSwitch('CREDIT_CARD', checked)}
        >
          <VStack w="100%" alignItems="flex-start" spacing={1}>
            <VStack alignItems="flex-start" spacing={1}>
              <HStack alignItems="flex-start">
                <Checkbox
                  isChecked={isCreditCardRestricted}
                  onChange={onCreditCardRestrictedChange}
                />
                <Text fontWeight={700} lineHeight="14px">
                  { t('paymentProfiles.forms.CREDIT_CARD.restricted.label') }
                </Text>
              </HStack>

              {
                isCreditCardRestricted &&
                  <Text color="gray.400">
                    { t('paymentProfiles.forms.CREDIT_CARD.restricted.helperText') }
                  </Text>
              }
            </VStack>

            {
              isCreditCardRestricted &&
                <>
                  <UsersSearchSelect
                    users={employees}
                    userIds={creditCard.userIds}
                    placeholder={t('paymentProfiles.forms.CREDIT_CARD.restricted.placeholder')}
                    onAddUser={onAddUser}
                  />

                  <RemovableUsersList
                    users={employees}
                    userIds={creditCard.userIds}
                    onRemoveUser={onRemoveUser}
                    border="none"
                    py={0}
                    pr="2px"
                    mt="2px"
                  />

                  {
                    isSubmitted && creditCard.userIds.length === 0 &&
                      <Text color="red.500">
                        { t('paymentProfiles.forms.CREDIT_CARD.noUsersError') }
                      </Text>
                  }
                </>
            }
          </VStack>
        </ProfileSwitch>
      </VStack>

      <PrimaryButton
        size="sm"
        colorScheme="teal"
        isDisabled={isSaveDisabled}
        position="fixed"
        bottom={5}
        right={5}
        isLoading={isSaving}
        onClick={onSave}
      >
        { t('common:buttons.save') }
      </PrimaryButton>
    </VStack>
  );
}