import { Text, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import QuoteRecipientSelector, { defaultState as defaultRecipientData, State as RecipientData } from 'src/components/QuoteRecipientSelector';
import { PrimaryButton } from 'src/components/buttons';
import { getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { getUserSelector } from 'src/store/user/user.selectors';
import Quotes from '../../../Quotes';
import { PoNumber } from '../PoNumber';

interface Props {
  quote: QuoteJSON | null;
  processNumber: BookingJSON['processNumber'];
  bookingsInvoices: BookingInvoiceJSON[];
  disabled: boolean;
  onSubmit(data: QuoteCreateBody): Promise<void>;
}

export function QuoteCard(props: Props) {
  const { t } = useTranslation('booking');

  const employees = useSelector(getEmployeesSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);

  const user = useSelector(getUserSelector);

  const [recipientData, setRecipientData] = useState<RecipientData>({
    ...defaultRecipientData,
    data: {
      ...defaultRecipientData.data,
      employee: user,
      employeeFirstName: user.firstName,
      employeeLastName: user.lastName
    }
  });

  const [processNumber, setProcessNumber] = useState(props.processNumber);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const priceDetails = useMemo(() => getBookingInvoicesTotalDetails(props.bookingsInvoices), [props.bookingsInvoices]);

  async function onSubmit() {
    setIsSubmitting(true);

    const { employee, employeeFirstName, employeeLastName, firstName, lastName, email } = recipientData.data;

    if (recipientData.selectedRadio === 'SEND_TO_EMAIL') {
      await props.onSubmit({
        email,
        firstName,
        lastName,
        processNumber
      });
    } else {
      await props.onSubmit({
        email: employee.email,
        userId: employee.id,
        firstName: employeeFirstName,
        lastName: employeeLastName,
        processNumber
      });
    }

    setIsSubmitting(false);
  };

  if (employeesFetchStatus !== FETCH_STATUS.FETCHED) {
    return <Loader />;
  }

  return (
    <>
      <PoNumber
        processNumber={processNumber}
        isProcessNumberRequired={false}
        isCommitment={!!props.quote}
        onChange={setProcessNumber}
      />

      {
        props.quote ? (
          <VStack w="100%" alignItems="flex-start" spacing={5}>
            <VStack w="100%" alignItems="flex-start" spacing="10px">
              <Text lineHeight="16px" fontWeight={700}>{t(`steps.commitment.quote.form.requireQuote.label`)}</Text>

              <Quotes quotes={[props.quote]} bookingsInvoices={props.bookingsInvoices} />
            </VStack>

            <BookingPriceDetailsCard
              subtotal={priceDetails.subtotal}
              totalTax={priceDetails.totalTax}
              totalDiscount={priceDetails.totalDiscount}
              total={priceDetails.total}
              currency={priceDetails.currency}
              showCurrencyWarning={true}
            />

            <AwaitingInfoCard label={t('steps.commitment.quote.form.awaitingQuote')} />
          </VStack>
        ) : (
          <VStack w="100%" alignItems="flex-start" spacing={5}>
            <VStack w="100%" alignItems="flex-start" spacing={3}>
              <Text lineHeight="16px" fontWeight={700}>{t('steps.commitment.quote.form.title')}</Text>

              <QuoteRecipientSelector
                user={user}
                employees={employees}
                currentUserSelectLabel={t('steps.commitment.quote.form.currentUserSelectLabel.label')}
                selectLabel={t('steps.commitment.quote.form.select.label')}
                formLabel={t('steps.commitment.quote.form.email.label')}
                onChange={setRecipientData}
              />
            </VStack>

            <BookingPriceDetailsCard
              subtotal={priceDetails.subtotal}
              totalTax={priceDetails.totalTax}
              totalDiscount={priceDetails.totalDiscount}
              total={priceDetails.total}
              currency={priceDetails.currency}
              showCurrencyWarning={true}
            />

            <PrimaryButton
              w="100%"
              size="sm"
              type="submit"
              colorScheme="blue"
              onClick={onSubmit}
              isLoading={isSubmitting}
              isDisabled={!recipientData.isValidState || props.disabled}
            >
              { t(`steps.commitment.quote.form.${recipientData.selectedRadio === 'SIGN_MYSELF' ? 'signQuote' : 'sendQuote'}`) }
            </PrimaryButton>
          </VStack>
        )
      }
    </>
  );
}